import { useParams } from 'react-router-dom';
import styles from './ProjectTransitionButtons.module.scss';
import {
  useGetProjectTransitionsQuery,
  useMakeProjectTransitionMutation,
} from '@store/project/project.slice';
import { Button } from '@components/MUI/Button';
import { ProjectTransition } from '@store/project/contracts';
import { useCallback } from 'react';
import { useAppDispatch } from '@hooks/index';
import { closeModal, openModal } from '@store/ui/ui.slice';
import {
  ProjectTransitionAdditionalFieldsForm,
  ProjectTransitionFields,
  ProjectTransitionAdditionalFieldsProps,
} from './index';

type ProjectTransitionProps = {
  projectPrefix?: string;
};

export const ProjectTransitions = ({ projectPrefix }: ProjectTransitionProps) => {
  const { projectId } = useParams();
  const dispatch = useAppDispatch();

  const [makeTransition, { isLoading }] = useMakeProjectTransitionMutation();
  const { data: transitions } = useGetProjectTransitionsQuery(projectId!, {
    skip: !projectId,
  });

  const handleMakeTransition: ProjectTransitionAdditionalFieldsProps['submit'] = useCallback(
    async (form) => {
      const body = {
        ...(projectPrefix && { prefix: projectPrefix }),
        ...form,
      };
      await makeTransition({
        projectId: projectId!,
        body,
      })
        .unwrap()
        .then(() => {
          dispatch(closeModal());
        })
        .catch((err) => {});
    },
    [makeTransition],
  );

  const openTransitionProjectModal = useCallback(
    (fields: ProjectTransitionFields, transitionId: string) => {
      dispatch(
        openModal(
          <ProjectTransitionAdditionalFieldsForm
            submit={handleMakeTransition}
            fields={fields}
            transitionId={transitionId}
          />,
        ),
      );
    },
    [dispatch, handleMakeTransition],
  );

  const handleTransition = useCallback(
    (transition: ProjectTransition) => {
      if (transition.fields && transition.fields.length > 0) {
        openTransitionProjectModal(transition.fields, transition.id!);
      } else {
        makeTransition({
          projectId: projectId!,
          body: {
            transitionId: transition.id!,
            ...(projectPrefix && { prefix: projectPrefix }),
          },
        })
          .unwrap()
          .catch((err) => {});
      }
    },
    [makeTransition, openTransitionProjectModal],
  );
  return (
    <div className={styles.container}>
      {transitions &&
        transitions.map((transition) => (
          <Button
            variant="text"
            key={transition.id}
            disabled={isLoading}
            onClick={() => handleTransition(transition)}>
            {transition.display}
          </Button>
        ))}
    </div>
  );
};
