import { Dayjs } from 'dayjs';
import React from 'react';
import styles from './ProfileFooter.module.scss';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { openModal } from '@store/ui/ui.slice';
import { DeleteAccountModal } from './DeleteAccountModal';
import { useParams } from 'react-router-dom';
import { INTEGRATION_PROVIDER } from '@store/user/contracts';
import { IntegrationProviderToggle } from '@pages/profile/profileComponents/profileFooter/IntegrationProviderToggle';

type ProfileFooterProps = {
  registration?: Dayjs;
};

export const ProfileFooter: React.FC<ProfileFooterProps> = ({ registration }) => {
  const dispatch = useAppDispatch();
  const { userID } = useParams();

  return (
    <div className={styles.footer}>
      <div className={styles.registration}>
        <p className="m-0 p-0 color-gray">Дата регистрации</p>
        <span className={styles.footer__content}>
          {registration ? registration.format('DD.MM.YYYY') : '-'}
        </span>
      </div>
      {!userID ? (
        <>
          {[INTEGRATION_PROVIDER.YANDEX, INTEGRATION_PROVIDER.SYS].map((provider) => {
            return <IntegrationProviderToggle type={provider} key={provider} />;
          })}
          <div className="mt-4">
            <Button
              className={styles.button__delete}
              color="error"
              size="large"
              onClick={() => dispatch(openModal(<DeleteAccountModal />))}>
              Удалить аккаунт
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
