import React, { useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { AnySchema, reach } from 'yup';
import { FormControl, FormHelperText, styled, TextField, TextFieldProps } from '@mui/material';

export type InputFieldProps = TextFieldProps & {
  name: string;
  placeholder?: string;
  type: React.HTMLInputTypeAttribute | undefined;
  className?: string;
  isLabel?: boolean;
};

export function InputField({
  type,
  placeholder,
  name,
  className,
  isLabel = true,
  ...props
}: InputFieldProps) {
  const contextForm = useFormikContext();
  const [field, meta] = useField(name);

  const fieldSchema: AnySchema | null = useMemo(() => {
    return contextForm.validationSchema ? reach(contextForm.validationSchema, name) : null;
  }, [contextForm.validationSchema, name]);

  const isRequiredField = useMemo(
    () => Boolean(fieldSchema?.tests.find((test) => test.OPTIONS.name === 'required')),
    [fieldSchema],
  );

  const hasError = useMemo(() => {
    return meta.touched && meta.error !== undefined;
  }, [meta.touched, meta.error]);

  const textFieldPlaceholder = useMemo(() => {
    return isRequiredField ? `${placeholder}*` : `${placeholder}`;
  }, [isRequiredField, placeholder]);

  const StyledTextField = useMemo(() => {
    return styled(TextField)({
      '& label.Mui-focused': {
        top: '10px',
        borderWidth: '1px',
      },
      '& label.MuiInputLabel-shrink': {
        top: '10px',
      },
      legend: {
        display: 'none',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--text-default-color); !important',
        borderWidth: '1px',
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'var(--text-default-color);',
          borderWidth: '1px',
        },
      },
    });
  }, []);

  const wheelHandler = (e: React.WheelEvent<HTMLDivElement>) => {
    //@ts-ignore
    e.target.blur();
  };

  return (
    <FormControl id={name} className={className}>
      <StyledTextField
        placeholder={textFieldPlaceholder ? textFieldPlaceholder : ''}
        id={name}
        label={isLabel ? textFieldPlaceholder : undefined}
        error={hasError}
        type={type}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        variant="outlined"
        multiline={props.multiline}
        onWheelCapture={type === 'number' ? wheelHandler : () => {}}
        InputLabelProps={
          type === 'date'
            ? {
                shrink: true,
                ...props.InputLabelProps,
              }
            : props.InputLabelProps
        }
        {...props}
      />
      {hasError && <FormHelperText error>{meta.error}</FormHelperText>}
    </FormControl>
  );
}
