import { NavigationBack } from '@components/NavigationBack/NavigationBack';
import React, { useCallback, useMemo, useState } from 'react';
import { Card, CircularProgress, Divider } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@components/MUI/Button';
import { EditIcon } from '@components/ui/Icons/EditIcon';
import { CloseIcon } from '@components/ui/Icons/CloseIcon';
import { StatusLabel, statusColors } from '@components/ui/StatusLabel/StatusLabel';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Dayjs } from 'dayjs';
import { ActionButtons } from '@components/ui/ActionButtons/ActionButtons';
import { closeModal, openModal } from '@store/ui/ui.slice';
import { CloseVacancyModal } from '../modals/CloseVacancyModal';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { selectCurrentUserId } from '@store/user/user.slice';
import styles from './VacancyPageDetail.module.scss';
import { Star } from '@components/Star/Star';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import { AdaptiveActionButtons } from '@components/adaptives/AdaptiveActionButtons';
import { useNavigateToUserChat } from '@hooks/useNavigateToUserChat';
import { useAcceptOfferMutation, useDenyOfferMutation } from '@store/offer/offer.slice';
import { useGetVacancyQuery, useSetFavoriteVacancyMutation } from '@store/vacancy/vacancy.slice';
import { useEditVacancy } from '@hooks/useEditVacancy';
import { vacancyConstants } from '@pages/teams/constans/vacancyConstant';

type TableOffer = {
  id: string;
  executor: string;
  vacancyName: string;
  stack: string;
  created: Dayjs;
  offerStatusName: string;
  executorId: string;
  actions: JSX.Element;
};

export const VacancyPageDetail: React.FC = () => {
  const { vacancyId } = useParams();
  const myId = useAppSelector(selectCurrentUserId);
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const createChat = useNavigateToUserChat();
  const [denyOffer] = useDenyOfferMutation();
  const [acceptOffer] = useAcceptOfferMutation();
  const [setFavoriteVacancy] = useSetFavoriteVacancyMutation();
  const { data: vacancy, isLoading } = useGetVacancyQuery(vacancyId!);
  const { handleEditVacancy } = useEditVacancy();
  const isMyVacancy = myId === vacancy?.author?.userId;

  const offers: TableOffer[] = useMemo(() => {
    const offers = vacancy?.offers.map((offer) => {
      const newOffer: TableOffer = {
        id: offer.id,
        executor: offer.executor.userName,
        executorId: offer.executor.userId,
        vacancyName: offer.vacancyName,
        stack: vacancy.stack,
        created: offer.created,
        offerStatusName: offer.offerStatusName,
        actions: (
          <ActionButtons
            isOnlySend={
              offer.offerStatusName === 'Оффер от исполнителя' ||
              offer.offerStatusName === 'Оффер от менеджера'
                ? false
                : true
            }
            handleApprove={() => handleApprove(offer?.id)}
            handleClose={() => handleClose(offer?.id)}
            handleSendMessage={() => handleSendMessage(offer?.executor?.userId)}
          />
        ),
      };
      return newOffer;
    });
    return offers || [];
  }, [vacancy]);

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const closeVacancyModal = useCallback(() => {
    dispatch(openModal(<CloseVacancyModal vacancyId={vacancy?.id} />));
  }, [vacancy]);

  const handleApprove = (id: string) => {
    acceptOffer(id);
    dispatch(closeModal());
  };

  const handleClose = (id: string) => {
    denyOffer(id);
    dispatch(closeModal());
  };

  const handleSendMessage = (executorId: string) => {
    createChat(executorId);
  };

  const handleClickEdit = () => {
    if (vacancy) {
      handleEditVacancy(vacancy);
    }
  };

  return (
    <div className="col container-content">
      <div className={styles.content__container}>
        {isLoading ? (
          <div className={styles.loadingBlock}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.navigation__back}>
              <NavigationBack />
            </div>
            <Card className={styles.vanancy__container} sx={{ boxShadow: 'none' }}>
              <div className="d-flex justify-content-between">
                <div className="d-flex mb-4">
                  <h2 className="m-0 me-1">
                    {vacancy?.name} - ({vacancy?.statusName})
                  </h2>
                  {vacancyId ? (
                    <Star
                      checked={vacancy?.favorite || false}
                      onChange={(value) =>
                        setFavoriteVacancy({ requestBody: { status: value }, vacancyId: vacancyId })
                      }
                      className={styles.star}
                    />
                  ) : (
                    <></>
                  )}
                </div>
                {isMyVacancy && vacancy?.statusName !== vacancyConstants.VACANCY_CLOSED ? (
                  <div>
                    <Button
                      disabled={!vacancy}
                      onClick={handleClickEdit}
                      variant="outlined"
                      startIcon={<EditIcon />}>
                      Редактировать
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex flex-column mb-3">
                <span className="color-gray">Дата публикации</span>
                <span className={styles.vacancy__publication__date}>
                  {vacancy?.created.format('DD.MM.YYYY')}
                </span>
              </div>
              <div className="mb-3">
                <div className={styles.team}>
                  <p className={styles.team__label}>Команда</p>
                  <div className="">
                    <Link to={`/teams/${vacancy?.teamId}`} className={styles.team__name}>
                      {vacancy?.teamName}
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles.role}>
                <div className={styles.field}>Роль</div>
                <div>
                  <p className={styles.field__value}>{vacancy?.positionName}</p>
                </div>
              </div>
              <div>
                <div className={styles.field}>Направление</div>
                <div>
                  <p className={styles.field__value}>{vacancy?.directionName}</p>
                </div>
              </div>
              <div className={styles.satck}>
                <div className={styles.field}>Стэк</div>
                <div>
                  <p className={styles.field__value}>{vacancy?.stack}</p>
                </div>
              </div>
              <div className={styles.payment}>
                <div className={styles.field}>Ставка</div>
                <div>
                  <p className={styles.field__value}>{vacancy?.payment} р/ч</p>
                </div>
              </div>
              <div className={styles.comment}>
                <div className={styles.comment__label}>Комментарий</div>
                <div>
                  <p className={styles.comment__value}>{vacancy?.comment}</p>
                </div>
              </div>
              <div className={styles.satck}>
                <div className={styles.field}>Статус вакансии</div>
                <div>
                  <p className={styles.field__value}>{vacancy?.statusName}</p>
                </div>
              </div>
              {isMyVacancy && vacancy?.statusName !== vacancyConstants.VACANCY_CLOSED ? (
                <div className={styles.close__button}>
                  <Button
                    startIcon={<CloseIcon />}
                    color="error"
                    onClick={closeVacancyModal}
                    variant="text"
                    sx={{
                      '&:hover': {
                        backgroundColor: 'transparent',
                        color: '#FF4E4E',
                        '& svg path': {
                          fill: '#FF4E4E',
                          stroke: '#FF4E4E !important',
                        },
                      },
                    }}>
                    Закрыть вакансию
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {isMyVacancy && (
                <>
                  <Divider />
                  <div>
                    <h2 className={styles.vacancy__responses__title}>Отклики на вакансию</h2>
                  </div>
                  {vacancy?.offers !== null ? (
                    <div className={styles.table__container}>
                      <AdaptiveTableMUI
                        columns={{
                          executor: {
                            label: 'ФИО',
                          },
                          actionsRaw: {
                            children: () => <div></div>,
                          },
                          vacancyName: {
                            label: 'Направление',
                          },
                          stack: {
                            label: 'Стэк',
                          },
                          created: {
                            label: 'Дата отклика',
                            format: (value: Dayjs) => value.format('DD.MM.YYYY'),
                          },
                          offerStatusName: {
                            label: 'Статус',
                            children: (value) => (
                              <StatusLabel
                                color={statusColors.get(value)}
                                text={value}></StatusLabel>
                            ),
                          },
                          actions: {
                            children: (_, raw: TableOffer) => (
                              <AdaptiveActionButtons
                                handleApprove={() => handleApprove(raw.id)}
                                handleClose={() => handleClose(raw.id)}
                                handleSendMessage={() => handleSendMessage(raw.executorId)}
                                isOnlySend={
                                  raw.offerStatusName === 'Оффер от исполнителя' ||
                                  raw.offerStatusName === 'Оффер от менеджера'
                                    ? false
                                    : true
                                }
                              />
                            ),
                          },
                        }}
                        rows={offers !== null && offers !== undefined ? offers : []}>
                        <TablePaginationMUI
                          limit={limit}
                          count={vacancy?.offers.length || 1}
                          entityLabel="откликов"
                          page={page}
                          handleChangePage={(_, newPage) => setPage(newPage)}
                          handleChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </AdaptiveTableMUI>
                    </div>
                  ) : (
                    <div>Откликов пока нет</div>
                  )}
                </>
              )}
            </Card>
          </>
        )}
      </div>
    </div>
  );
};
