import { ApiClient } from '@store/api-client';
import { plainToInstance } from 'class-transformer';
import {
  INTEGRATION_PROVIDER,
  SysProject,
  YandexOrganization,
  YandexOrganizationProject,
} from '@store/user/contracts';

export const resolveConnectLinkToIntegrationProvider = async (
  provider: INTEGRATION_PROVIDER,
  redirect: string,
) => {
  const { data } = await ApiClient.get<string>(
    `api/integration/${provider.toLowerCase()}/connect`,
    {
      params: {
        redirect,
      },
    },
  );
  return data;
};

export const disconnectIntegrationProvider = async (provider: INTEGRATION_PROVIDER) => {
  await ApiClient.post(`api/integration/${provider.toLowerCase()}/disconnect`);
};

export const listYandexOrganization = async () => {
  const { data } = await ApiClient.get<any[]>(`api/integration/yandex/organizations`);
  return plainToInstance(YandexOrganization, data);
};

export const listProjectInYandexOrganization = async (orgId: string) => {
  const { data } = await ApiClient.get<any[]>(
    `api/integration/yandex/organizations/${orgId}/projects`,
  );
  return plainToInstance(YandexOrganizationProject, data);
};

export const listSysProjects = async () => {
  const { data } = await ApiClient.get<SysProject[]>(`api/integration/sys/projects`);
  return data;
};
