import { ChartDataType, ThroughputChart } from './ThroughputChart';
import styles from './Throughput.module.scss';
import { Helper } from '@components/ui/Helper';
import { ChartLegend } from '@components/ChartLegend/ChartLegend';
import { useContrastingColors } from '@hooks/useContrastingColors';
import { PeriodPicker } from './PeriodPickerForm';
import { useField, useFormikContext } from 'formik';
import { FormValues } from '../AdvancedAnalytics';
import { useParams } from 'react-router-dom';
import { useGetThroughputAnalyticQuery } from '@store/project/project.slice';
import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';

const UNITS = [
  {
    value: 'month',
    label: 'Месяц(а)',
  },
  {
    value: 'week',
    label: 'Неделя(и)',
  },
  {
    value: 'day',
    label: 'Дни',
  },
];

export const Throughput = () => {
  const { values } = useFormikContext<FormValues>();

  const [unitField] = useField('unit');

  const labels = ['Прохор Ситников', 'Илларион Гурьев', 'Августа Дьячкова', 'Кристина Горбачёва'];
  const colors = useContrastingColors(labels.length);

  const { projectId } = useParams();
  const projectIdToUse = projectId || '';

  const { data } = useGetThroughputAnalyticQuery({
    path: {
      projectId: projectIdToUse,
    },
    query: {
      startDate: values.period[0].format('YYYY-MM-DD'),
      endDate: values.period[1].format('YYYY-MM-DD'),
      unit: unitField.value,
    },
  });

  const axisLabels = useMemo(() => {
    if (data)
      return data.map(
        (item) =>
          `${dayjs(item.startDate).format('DD.MM')} - ${dayjs(item.endDate).format('DD.MM')}`,
      );
    return [];
  }, [data]);

  const getUniqueExecutors = useCallback((): string[] => {
    if (!data) return [];
    const executors = new Set<string>();
    for (const day of data) {
      for (const entry of day.data) {
        executors.add(entry.executorName === null ? '' : entry.executorName);
      }
    }
    return Array.from(executors);
  }, [data]);

  const executors = getUniqueExecutors();

  const organizeTaskCounts = useCallback(() => {
    if (!data) return [];
    const result = [];

    for (const executor of executors) {
      const executorTasks = [];
      for (const day of data) {
        const executorData = day.data.find((item) => item.executorName === executor);
        if (executorData) {
          executorTasks.push(executorData.taskCount);
        } else {
          executorTasks.push(0); // Default to 0 if no tasks found for the executor on that day
        }
      }
      result.push(executorTasks);
    }

    return result;
  }, [data]);

  const organizeAdditionalInfo = useCallback(() => {
    if (!data) return [];
    const result = [];

    for (const executor of executors) {
      const executorTasks = [];
      for (const day of data) {
        const executorData = day.data.find((item) => item.executorName === executor);
        if (executorData) {
          executorTasks.push({
            percentage: executorData.percentage,
            total: day.totalTaskCount,
          });
        } else {
          executorTasks.push(null); // Default to null if no tasks found for the executor on that day
        }
      }
      result.push(executorTasks);
    }

    return result;
  }, [data]);

  const findMaxTotalTaskCount = useMemo(() => {
    if (!data) return 0;
    else
      return data?.reduce((max, current) => {
        return current.totalTaskCount > max ? current.totalTaskCount : max;
      }, 0);
  }, []);

  const maxTotalTaskCount = findMaxTotalTaskCount;

  const additionalInfoOfExecutors = organizeAdditionalInfo();

  const correctDataSeries = organizeTaskCounts();

  const getCorrectChartSeries = useCallback(
    (dataArrays: number[][], labels: string[], colors: string[]): ChartDataType[] => {
      return dataArrays.map((data, i) => ({
        data: data,
        label: labels[i],
        id: `${labels[i]}Id`,
        stack: 'total',
        color: colors[i],
        additionalInfo: additionalInfoOfExecutors[i],
      }));
    },
    [],
  );
  const correctChartSeries = useMemo(
    () => getCorrectChartSeries(correctDataSeries, executors, colors),
    [correctDataSeries, executors, colors, getCorrectChartSeries],
  );

  const isEmpty = executors.length === 0;

  return (
    <>
      <div
        className={styles.throughput_container}
        style={{
          order: data ? 2 : 1,
        }}>
        <h2 className={styles.header}>
          <span>Throughput</span>
          {
            <Helper title="Метрика, которая показывает какой объем работы команда может выполнить за определенный период (количество завершенных задач)." />
          }
        </h2>
        <p className={styles.period}>
          {values.period[0].format('DD.MM.YYYY')} - {values.period[1].format('DD.MM.YYYY')}
        </p>
        <p className={styles.periodPicker_title}>Промежуток</p>
        <PeriodPicker units={UNITS} />
        {!isEmpty ? (
          <>
            <div className={styles.yAxis_label}>
              <p>Выполненные задачи</p>
            </div>
            <div className={styles.chart_container}>
              <ThroughputChart
                chartData={correctChartSeries}
                xAxisLabels={axisLabels}
                maxTotal={maxTotalTaskCount}
              />
            </div>
            <div className={styles.chart_legend}>
              <ChartLegend labels={executors} colors={colors} />
            </div>
          </>
        ) : (
          <div className={styles.emptyData}>
            <p>Нет данных</p>
          </div>
        )}
      </div>
    </>
  );
};
