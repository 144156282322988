import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTemplateRequest,
  ProjectWithCloud,
  Template,
  PureCloudResource,
  CloudInfrastructure,
  ItemCloudDelegationRequest,
  ItemCloudInfrastructureRequest,
  ItemCloudDeploymentResourceRequest,
  ItemCloudInstanceResourceRequest,
  CloudDelegation,
  CloudDeploymentRule,
  CloudInstanceResource,
  CloudImageResource,
  CloudShedule,
  CloudDeploymentAction,
  CloudDeploymentResource,
  EditTemplateParams,
  CloudResourceStatus,
  ResourceRequest,
} from './contracts';
import * as CloudService from '@store/cloud/cloud.service';
import { PaginationQuery } from '@store/common';
import { RootState } from '@store/index';
import { apiErrorHandler } from '@store/api-client';
import { BudgetRuleValue } from '@pages/project/components/BudgetRule/BudgetRuleForm';
import { enqueueSnackbar } from 'notistack';

const PROJECT_STATUS_SUCCESS = 'Выделены права для сервисного аккаунта';

type CloudState = {
  instances: PureCloudResource[];
  templates: Template[];
  projects: ProjectWithCloud[];
  organizations: PureCloudResource[];
  clouds: PureCloudResource[];
  accounts: PureCloudResource[];
  folders: PureCloudResource[];
  networks: PureCloudResource[];
  infrastructures: CloudInfrastructure[];
  delegations: CloudDelegation[];
  deploymentRules: CloudDeploymentRule[];
  images: CloudImageResource[];
  shedules: CloudShedule[];
  deploymentActions: CloudDeploymentAction[];
  deployments: CloudDeploymentResource[];
  cloudInstances: CloudInstanceResource[];
  zones: PureCloudResource[];
  platforms: PureCloudResource[];
  resourceStatuses: CloudResourceStatus[];
  instanceGroups: PureCloudResource[];
};

const initialState: CloudState = {
  instances: [],
  templates: [],
  projects: [],
  organizations: [],
  clouds: [],
  accounts: [],
  folders: [],
  networks: [],
  infrastructures: [],
  delegations: [],
  deploymentRules: [],
  images: [],
  shedules: [],
  deploymentActions: [],
  deployments: [],
  cloudInstances: [],
  zones: [],
  platforms: [],
  resourceStatuses: [],
  instanceGroups: [],
};

export const cloudSlice = createSlice({
  name: 'cloud',
  initialState,
  reducers: {
    setInstances: (state, action: PayloadAction<{ instances: PureCloudResource[] }>) => {
      state.instances = [...action.payload.instances];
    },
    setTemplates: (state, action: PayloadAction<{ templates: Template[] }>) => {
      state.templates = [...action.payload.templates].map((template) => ({
        ...template,
        memory: Math.ceil(template.memory / 1024 / 1024 / 1024),
        diskSize: Math.ceil(template.diskSize / 1024 / 1024 / 1024),
      }));
    },
    setProjects: (state, action: PayloadAction<{ projects: ProjectWithCloud[] }>) => {
      state.projects = [...action.payload.projects].filter(
        (project) => project.status === PROJECT_STATUS_SUCCESS,
      );
    },
    setOrganizations: (state, action: PayloadAction<{ organizations: PureCloudResource[] }>) => {
      state.organizations = [...action.payload.organizations];
    },
    setClouds: (state, action: PayloadAction<{ clouds: PureCloudResource[] }>) => {
      state.clouds = [...action.payload.clouds];
    },
    setBillingAccounts: (state, action: PayloadAction<{ accounts: PureCloudResource[] }>) => {
      state.accounts = [...action.payload.accounts];
    },
    setFolders: (state, action: PayloadAction<{ folders: PureCloudResource[] }>) => {
      state.folders = [...action.payload.folders];
    },
    setNetworks: (state, action: PayloadAction<{ networks: PureCloudResource[] }>) => {
      state.networks = [...action.payload.networks];
    },
    setInfrastuctures: (
      state,
      action: PayloadAction<{ infrastructures: CloudInfrastructure[] }>,
    ) => {
      state.infrastructures = [...action.payload.infrastructures];
    },
    setDelegations: (state, action: PayloadAction<{ delegations: CloudDelegation[] }>) => {
      state.delegations = [...action.payload.delegations];
    },
    setDeploymentRules: (
      state,
      action: PayloadAction<{ deploymentRules: CloudDeploymentRule[] }>,
    ) => {
      state.deploymentRules = [...action.payload.deploymentRules];
    },
    setImages: (state, action: PayloadAction<{ images: CloudImageResource[] }>) => {
      state.images = [...action.payload.images];
    },
    setShedules: (state, action: PayloadAction<{ shedules: CloudShedule[] }>) => {
      state.shedules = [...action.payload.shedules];
    },
    setDeploymentActions: (
      state,
      action: PayloadAction<{ deploymentActions: CloudDeploymentAction[] }>,
    ) => {
      state.deploymentActions = [...action.payload.deploymentActions];
    },
    setDeployments: (state, action: PayloadAction<{ deployments: CloudDeploymentResource[] }>) => {
      state.deployments = [...action.payload.deployments];
    },
    setCloudInstances: (
      state,
      action: PayloadAction<{ cloudInstances: CloudInstanceResource[] }>,
    ) => {
      state.cloudInstances = [...action.payload.cloudInstances].map((cloudInstance) => ({
        ...cloudInstance,
        memory: Math.ceil(cloudInstance.memory / 1024 / 1024 / 1024),
        diskSize: Math.ceil(cloudInstance.diskSize / 1024 / 1024 / 1024),
      }));
    },
    setZones: (state, action: PayloadAction<{ zones: PureCloudResource[] }>) => {
      state.zones = [...action.payload.zones];
    },
    setPlatforms: (state, action: PayloadAction<{ platforms: PureCloudResource[] }>) => {
      state.platforms = [...action.payload.platforms];
    },
    setResourceStatuses: (
      state,
      action: PayloadAction<{ resourceStatuses: CloudResourceStatus[] }>,
    ) => {
      state.resourceStatuses = [...action.payload.resourceStatuses];
    },
    setInstanceGroups: (state, action: PayloadAction<{ instanceGroups: PureCloudResource[] }>) => {
      state.instanceGroups = [...action.payload.instanceGroups];
    },
  },
});

export const loadProjects = createAsyncThunk(
  'cloud/projects/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getProjectsWithClouds(request);
    await dispatch(setProjects({ projects: result.data }));
  },
);

export const loadTemplates = createAsyncThunk(
  'cloud/templates/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getTemplateList(request);
    await dispatch(setTemplates({ templates: result.data }));
  },
);

export const createTemplate = createAsyncThunk(
  'cloud/templates/create',
  async (request: CreateTemplateRequest, { dispatch }) => {
    try {
      const response = await CloudService.createTemplate({
        name: request.name,
        memory: request.memory * 1024 * 1024 * 1024,
        cores: request.cores,
        diskSize: request.diskSize * 1024 * 1024 * 1024,
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editTemplate = createAsyncThunk(
  'cloud/templates/edit',
  async (params: EditTemplateParams) => {
    try {
      const response = await CloudService.editTemplate(params);
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createBudgetRule = createAsyncThunk(
  'cloud/budget-rule/create',
  async (request: BudgetRuleValue, { dispatch }) => {
    try {
      const response = await CloudService.createBudgetRule(request);
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const setCloudForProject = createAsyncThunk(
  'cloud/yandex/set',
  async (request: { projectId: string; cloudProjectId: string }, { dispatch }) => {
    try {
      const response = await CloudService.selectCloud(request);
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const selectTemplates = (state: RootState) => state.cloud.templates;

export const selectTemplateOptions = (state: RootState) => {
  return state.cloud.templates.map((template) => ({
    value: template.id,
    label: template.name,
  }));
};

export const selectProjectOptions = (state: RootState) =>
  state.cloud.projects.map((project) => ({
    value: project.projectId,
    label: project.projectName,
  }));
export const selectDetailTemplate = createSelector(
  (state: RootState, templateId: string) => state.cloud.templates.find((t) => t.id === templateId),
  (template) => template,
);

export const selectDetailInstance = createSelector(
  (state: RootState, instanceId: string) => state.cloud.instances.find((t) => t.id === instanceId),
  (instance) => instance,
);

export const loadOrganizations = createAsyncThunk(
  'cloud/organizations/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getOrganizationsWithClouds(
      params.delegationId,
      params.request,
    );
    await dispatch(setOrganizations({ organizations: result }));
  },
);
export const selectOrganizations = (state: RootState) => state.cloud.organizations;
export const selectOrganizationOptions = (state: RootState) =>
  state.cloud.organizations.map((organization) => ({
    value: organization.id,
    label: organization.name,
  }));

export const loadClouds = createAsyncThunk(
  'cloud/clouds/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getCloudsWithClouds(params.delegationId, params.parentId);
    await dispatch(setClouds({ clouds: result }));
  },
);

export const selectClouds = (state: RootState) => state.cloud.clouds;
export const selectCloudOptions = (state: RootState) => {
  const options = state.cloud.clouds.map((cloud) => ({
    value: cloud.id,
    label: cloud.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};
/*
  state.cloud.clouds.map((cloud) => ({
    value: cloud.id,
    label: cloud.name,
  }));
*/
export const loadFolders = createAsyncThunk(
  'cloud/folders/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setFolders({ folders: [] }));
      return;
    }
    const result = await CloudService.getFoldersWithClouds(params.delegationId, params.parentId);
    await dispatch(setFolders({ folders: result }));
  },
);

export const selectFolders = (state: RootState) => state.cloud.folders;
export const selectFolderOptions = (state: RootState) => {
  const options = state.cloud.folders.map((folder) => ({
    value: folder.id,
    label: folder.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadNetworks = createAsyncThunk(
  'cloud/network/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setNetworks({ networks: [] }));
      return;
    }
    const result = await CloudService.getNetworksWithClouds(params.delegationId, params.parentId);
    await dispatch(setNetworks({ networks: result }));
  },
);

export const selectNetworks = (state: RootState) => state.cloud.networks;
export const selectNetworkOptions = (state: RootState) => {
  const options = state.cloud.networks.map((network) => ({
    value: network.id,
    label: network.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadInstances = createAsyncThunk(
  'cloud/instance/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setInstances({ instances: [] }));
      return;
    }
    const result = await CloudService.getInstancesWithClouds(
      params.infrastructureId,
      params.parentId,
    );
    await dispatch(setInstances({ instances: result }));
  },
);

export const selectInstances = (state: RootState) => state.cloud.instances;
export const selectInstanceOptions = (state: RootState) => {
  const options = state.cloud.instances.map((instance) => ({
    value: instance.id,
    label: instance.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadBillingAccounts = createAsyncThunk(
  'cloud/account/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getBillingAccountsWithClouds(
      params.delegationId,
      params.request,
    );
    await dispatch(setBillingAccounts({ accounts: result }));
  },
);

export const selectBillingAccounts = (state: RootState) => state.cloud.accounts;
export const selectBillingAccountOptions = (state: RootState) =>
  state.cloud.accounts.map((account) => ({
    value: account.id,
    label: account.name,
  }));

export const loadInfrastructures = createAsyncThunk(
  'cloud/infrastructure/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudInfrastructureList(request);
    await dispatch(setInfrastuctures({ infrastructures: result.data }));
  },
);

export const selectInfrastructures = (state: RootState) => state.cloud.infrastructures;
export const selectInfrastructureOptions = (state: RootState) =>
  state.cloud.infrastructures.map((infrastructure) => ({
    value: infrastructure.id,
    label: infrastructure.name,
  }));

export const selectDetailInfrastructure = createSelector(
  (state: RootState, infrastructureId: string) =>
    state.cloud.infrastructures.find((i) => i.id === infrastructureId),
  (infrastructure) => infrastructure,
);

export const loadCloudDelegations = createAsyncThunk(
  'cloud/delegation/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDelegationList(request);
    await dispatch(setDelegations({ delegations: result.data }));
  },
);

export const selectCloudDelegations = (state: RootState) => state.cloud.delegations;
export const selectCloudDelegationOptions = (state: RootState) => {
  const allDelegationOptions = state.cloud.delegations.map((delegation) => ({
    value: delegation.id,
    label: delegation.name,
  }));
  allDelegationOptions.push({ label: 'Без основания', value: '' });
  return allDelegationOptions;
};

export const createDelegation = createAsyncThunk(
  'cloud/delegation/create',
  async (request: ItemCloudDelegationRequest) => {
    try {
      const response = await CloudService.saveCloudDelegation({
        name: request.name,
        parentId: request.parentId,
        organization: request.organization,
        cloud: request.cloud,
        billingAccount: request.billingAccount,
        projectId: request.projectId,
        user: request.user,
        isEnabledCreateInfrastructure: request.isEnabledCreateInfrastructure,
        //infrastructureId: request.infrastructureId,
        isAllocateBudget: request.allocateBudget,
        currencyId: request.currencyId,
        budget: request.budget,
        budgetLimitAction: request.budgetLimitActionId,
        resetBudgetType: request.resetBudgetType,
        //@ts-ignore
        budgetEndDate: request.budgetEndDate,
      });
      enqueueSnackbar(`Делегирование успешно создано`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editDelegation = createAsyncThunk(
  'cloud/delegation/create',
  async (request: ItemCloudDelegationRequest) => {
    try {
      const response = await CloudService.saveCloudDelegation({
        id: request.id,
        name: request.name,
        parentId: request.parentId,
        organization: request.organization,
        cloud: request.cloud,
        billingAccount: request.billingAccount,
        projectId: request.projectId,
        user: request.user,
        isEnabledCreateInfrastructure: request.isEnabledCreateInfrastructure,
        //infrastructureId: request.infrastructureId,
        allocateBudget: request.allocateBudget,
        currencyId: request.currencyId,
        budget: request.budget,
        budgetLimitAction: request.budgetLimitActionId,
        resetBudgetType: request.resetBudgetType,
        budgetEndDate: request.budgetEndDate, // ?.format('DD-MM-YYYY')
      });
      enqueueSnackbar(`Делегирование успешно отредактировано`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createInfrastructure = createAsyncThunk(
  'cloud/infrastucture/create',
  async (request: ItemCloudInfrastructureRequest) => {
    try {
      const response = await CloudService.saveCloudInfrastructure({
        name: request.name,
        organization: request.organization,
        cloud: request.cloud,
        folder: request.folder,
        network: request.network,
        serviceAccount: request.serviceAccount,
        delegationId: request.delegationId,
      });
      enqueueSnackbar(`Инфраструктура успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const editInfrastructure = createAsyncThunk(
  'cloud/infrastucture/edit',
  async (request: ItemCloudInfrastructureRequest) => {
    try {
      const response = await CloudService.saveCloudInfrastructure({
        id: request.id,
        name: request.name,
        organization: request.organization,
        cloud: request.cloud,
        folder: request.folder,
        network: request.network,
        serviceAccount: request.serviceAccount,
        delegationId: request.delegationId,
      });
      enqueueSnackbar(`Инфраструктура отредактирована`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createDeploymentResource = createAsyncThunk(
  'cloud/deployment/create',
  async (request: ItemCloudDeploymentResourceRequest) => {
    try {
      const response = await CloudService.saveCloudDeploymentResource({
        id: request.id,
        name: request.name,
        infrastructureId: request.infrastructureId,
        instanceParamsId: request.instanceParamsId,
        instances: request.instances,
        ruleId: request.ruleId,
        numberInstance: request.numberInstance,
        isUseMasterInstance: request.isUseMasterInstance,
        masterInstanceId: request.masterInstanceId,
        imageId: request.imageId,
        sheduleId: request.sheduleId,
        startActionId: request.startActionId,
        endActionId: request.endActionId,
        zone: request.zone,
        platform: request.platform,
        isPreemptible: request.isPreemptible,
        instanceGroup: request.instanceGroup,
        positionId: request.positionId,
      });
      enqueueSnackbar(`Правило развертывания успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const createInstanceResource = createAsyncThunk(
  'cloud/instance/create',
  async (request: ItemCloudInstanceResourceRequest) => {
    try {
      const response = await CloudService.saveCloudInstanceResource({
        id: request?.id,
        name: request.name,
        instance: request.instance,
        infrastructureId: request.infrastructureId,
        deploymentId: request.deploymentId,
        cores: request.cores,
        memory: request.memory * 1024 * 1024 * 1024,
        owners: request.owners,
        statusId: request.statusId,
        imageId: request.imageId,
        diskSize: request.diskSize * 1024 * 1024 * 1024,
        zone: request.zone,
        platform: request.platform,
        login: request.login,
        password: request.password,
        ipAddress: request.ipAddress,
        instanceParamsId: request.instanceParamsId,
        remoteName: request.remoteName,
        isPreemptible: request.isPreemptible,
      });
      enqueueSnackbar(`Виртуальная машина успешно создана`, {
        variant: 'success',
      });
      return response;
    } catch (error) {
      apiErrorHandler(error);
    }
  },
);

export const loadCloudDeploymentRules = createAsyncThunk(
  'cloud/deployment/rule/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDeploymentRuleList(request);
    await dispatch(setDeploymentRules({ deploymentRules: result.data }));
  },
);

export const selectDeploymentRules = (state: RootState) => state.cloud.infrastructures;
export const selectDeploymentRuleOptions = (state: RootState) =>
  state.cloud.deploymentRules.map((deploymentRule) => ({
    value: deploymentRule.id,
    label: deploymentRule.name,
  }));

export const loadCloudInstances = createAsyncThunk(
  'cloud/instance/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudInstanceResourceList(request);
    await dispatch(setCloudInstances({ cloudInstances: result.data }));
  },
);

export const selectCloudInstances = (state: RootState) => state.cloud.cloudInstances;
export const selectCloudInstanceOptions = (state: RootState) =>
  state.cloud.cloudInstances.map((cloudInstance) => ({
    value: cloudInstance.id,
    label: cloudInstance.name,
  }));

export const loadImages = createAsyncThunk(
  'cloud/image/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudImageResourceList(request);
    await dispatch(setImages({ images: result.data }));
  },
);

export const selectImages = (state: RootState) => state.cloud.images;
export const selectImageOptions = (state: RootState) =>
  state.cloud.images.map((image) => ({
    value: image.id,
    label: image.name,
  }));

export const loadShedules = createAsyncThunk(
  'cloud/shedule/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudSheduleList(request);
    await dispatch(setShedules({ shedules: result.data }));
  },
);

export const selectShedules = (state: RootState) => state.cloud.shedules;
export const selectSheduleOptions = (state: RootState) =>
  state.cloud.shedules.map((shedule) => ({
    value: shedule.id,
    label: shedule.name,
  }));

export const loadDeploymentActions = createAsyncThunk(
  'cloud/deployment/action/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDeploymentActionList(request);
    await dispatch(setDeploymentActions({ deploymentActions: result.data }));
  },
);

export const selectDeploymentActions = (state: RootState) => state.cloud.deploymentActions;
export const selectDeploymentActionOptions = (state: RootState) =>
  state.cloud.deploymentActions.map((deploymentAction) => ({
    value: deploymentAction.id,
    label: deploymentAction.name,
  }));

export const loadDeployments = createAsyncThunk(
  'cloud/deployment/get',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudDeploymentResourceList(request);
    await dispatch(setDeployments({ deployments: result.data }));
  },
);

export const selectDeployments = (state: RootState) => state.cloud.deployments;
export const selectDeploymentOptions = (state: RootState) =>
  state.cloud.deployments.map((deployment) => ({
    value: deployment.id,
    label: deployment.name,
  }));

export const loadZones = createAsyncThunk(
  'cloud/zone/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getZoneWithClouds(params.infrastructureId, params.request);
    await dispatch(setZones({ zones: result }));
  },
);

export const selectZones = (state: RootState) => state.cloud.zones;
export const selectZoneOptions = (state: RootState) =>
  state.cloud.zones.map((zone) => ({
    value: zone.id,
    label: zone.id,
  }));

export const loadPlatforms = createAsyncThunk(
  'cloud/platform/get',
  async (params: ResourceRequest, { dispatch }) => {
    const result = await CloudService.getPlatformWithClouds(
      params.infrastructureId,
      params.request,
    );
    await dispatch(setPlatforms({ platforms: result }));
  },
);

export const selectPlatforms = (state: RootState) => state.cloud.platforms;
export const selectPlatformOptions = (state: RootState) =>
  state.cloud.platforms.map((platform) => ({
    value: platform.id,
    label: platform.id,
  }));

export const loadInstanceGroups = createAsyncThunk(
  'cloud/instance/get',
  async (params: ResourceRequest, { dispatch }) => {
    if (params.parentId === undefined || params.parentId === null || params.parentId === '') {
      await dispatch(setInstanceGroups({ instanceGroups: [] }));
      return;
    }
    const result = await CloudService.getInstanceGroupsWithClouds(
      params.infrastructureId,
      params.parentId,
    );
    await dispatch(setInstanceGroups({ instanceGroups: result }));
  },
);

export const selectInstanceGroups = (state: RootState) => state.cloud.instanceGroups;
export const selectInstanceGroupOptions = (state: RootState) => {
  const options = state.cloud.instanceGroups.map((instanceGroup) => ({
    value: instanceGroup.id,
    label: instanceGroup.name,
  }));
  options.unshift({ value: '', label: 'Создать' });
  return options;
};

export const loadCloudResourceStatus = createAsyncThunk(
  'cloud/resource/status/list',
  async (request: Partial<PaginationQuery>, { dispatch }) => {
    const result = await CloudService.getCloudResourceStatus(request);
    await dispatch(setResourceStatuses({ resourceStatuses: result }));
  },
);

export const {
  setInstances,
  setTemplates,
  setProjects,
  setOrganizations,
  setClouds,
  setBillingAccounts,
  setInfrastuctures,
  setDelegations,
  setFolders,
  setNetworks,
  setDeploymentRules,
  setImages,
  setShedules,
  setDeploymentActions,
  setDeployments,
  setCloudInstances,
  setZones,
  setPlatforms,
  setResourceStatuses,
  setInstanceGroups,
} = cloudSlice.actions;
export const cloudReducer = cloudSlice.reducer;
