import { Card as MCard, CardContent as MCardContent, Typography, styled } from '@mui/material';
import styles from '../AboutBenefits.module.scss';

export interface AboutCardProps {
  countCard: string;
  title?: string;
  colorTitle?: string;
  description?: string;
  image?: string;
}

export const Card = styled(MCard)`
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  box-shadow: none;
  padding: 0;
`;

export const CardContent = styled(MCardContent)`
  display: flex;
  padding: 0;
  &:last-child {
    padding: 0;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: end;
    }
  }
`;

export const NumberCard = styled(Typography)`
  color: #007236;
  font-size: 48px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  font-family: Roboto, sans-serif;
  span {
    color: #007236;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const DescriptionCard = styled(Typography)`
  display: flex;
  color: #78858b;
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export function AboutCard({ countCard, colorTitle, title, description, image }: AboutCardProps) {
  return (
    <Card>
      <CardContent>
        <div className={styles.containerAboutCard}>
          <NumberCard>{countCard}</NumberCard>
          <Title>
            <span>{colorTitle}</span> {title}
          </Title>
          <DescriptionCard>{description}</DescriptionCard>
        </div>
        <div>
          <img src={image} alt="" />
        </div>
      </CardContent>
    </Card>
  );
}
