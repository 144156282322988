import { FC, useCallback } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Checkbox as MCheckbox, FormControlLabel, CheckboxProps } from '@mui/material';

type CheckboxData = {
  label?: string;
  name: string;
  fieldClassName?: string;
} & CheckboxProps;

const Checkbox: FC<CheckboxData> = ({ label, name, fieldClassName, ...props }) => {
  const form = useFormikContext<FormikValues>();

  const handleChange = useCallback(() => {
    const currentValue = form?.values?.[name];
    form.setFieldValue(name, !currentValue);
  }, [form, name]);

  return (
    <FormControlLabel
      className={fieldClassName}
      control={
        <MCheckbox
          onChange={handleChange}
          checked={form.values[name]}
          sx={{
            '&.Mui-checked': {
              color: '#007236',
            },
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          {...props}
        />
      }
      label={label}
    />
  );
};

export { Checkbox };
