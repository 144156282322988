import { Select, SelectOption } from '@components/MUI/Select';
import { listSysProjects } from '@store/user/integration.service';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { AddTrackerValue } from '../AddTrackerForm';
import { CircularProgress, SelectChangeEvent } from '@mui/material';
import styles from '../AddTrackerForm.module.scss';

export const SysTracker = () => {
  const [sysProjects, setSysProjects] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const form = useFormikContext<AddTrackerValue>();

  useEffect(() => {
    setIsLoading(true);
    listSysProjects()
      .then((projects) => {
        setSysProjects(projects.map((project) => ({ value: project.id, label: project.name })));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={styles.sys_container}>
          <CircularProgress />
        </div>
      ) : sysProjects.length > 0 ? (
        <Select
          values={sysProjects}
          name="externalProjectId"
          isOutlined
          onChange={(event: SelectChangeEvent) => {
            form.setFieldValue('externalProjectId', event.target.value);
          }}
          label="Проекты"
        />
      ) : (
        <p>Проекты отсутствуют</p>
      )}
    </>
  );
};
