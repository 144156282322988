import { ErrorResponse, IDResponse, PaginationClass, ToDate } from '@store/common';
import { ItemType, Priority, Queue, TaskStatusType } from '@store/tasks/contracts';
import { INTEGRATION_PROVIDER, User } from '@store/user/contracts';
import { RequestBody } from '@store/utility';
import { Vacancy } from '@store/vacancy/contracts';
import { Type } from 'class-transformer';
import { Dayjs } from 'dayjs';
import Api from '@api-schema';

export class Project {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  name!: string;
  projectStatusId!: string;
  projectStatusName!: string;
  projectTypeId!: string;
  projectTypeName!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  deadLine!: Dayjs;
  budget!: number;
  description!: string;
  stack!: string;
  requirement!: string;
  task!: string;
  tag!: string;
  requestId!: string;
  requestName!: string;
  teamId!: string | null;
  teamName!: string | null;
  teamLeadId!: string | null;
  teamLeadName!: string | null;
  @Type(() => TeamPosition)
  teamPositions!: TeamPosition[];
  @Type(() => ProjectTracker)
  tracker!: ProjectTracker;
  @Type(() => CloudProjectODTO)
  cloudProjectODTO!: CloudProjectODTO;
  @Type(() => User)
  customer!: User;
  @Type(() => Attachments)
  attachments!: Attachments[];
  visibilityStatusId!: string;
  prefix!: string;
}

export class ProjectTracker {
  trackerProjectId!: number;
  organizationId!: number;
  provider!: string;
}

export class CloudProjectODTO {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  folderId!: string;
  status!: string;
  code!: number;
}

export class Attachments {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  favorite!: boolean;
  name!: string;
  size!: number;
  mime!: string;
  extension!: string;
  tag!: string;
  @Type(() => Owner)
  owner!: Owner;
}

export class Owner {
  userId!: string;
  userName!: string;
  storedFileId!: string;
}

export class ProjectLowModel {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  projectStatusName!: string;
  name!: string;
  membersCount!: number;
  @ToDate('DD.MM.YYYY HH:mm')
  deadline!: Dayjs;
}

export class GetProjectsResponse extends PaginationClass {
  @Type(() => Project)
  data!: Project[];
  recordsTotal!: number;
  recordsFiltered!: number;
}

export class GetTeamProjectsResponse extends PaginationClass {
  @Type(() => ProjectLowModel)
  data!: ProjectLowModel[];
}

export class GetManagerProjectsResponse extends PaginationClass {
  @Type(() => Project)
  data!: Project[];
}

export interface ProjectUpdateRequest {
  id: string;
  name?: string;
  projectStatusId?: string;
  projectTypeId?: string;
  deadLine?: string; // 01.01.2025 10:00
  budget?: number;
  description?: string;
  stack?: string;
  requirement?: string;
  task?: string;
  teamId?: string;
  attachments?: string[];
}

export type ProjectUpdateResponse = ErrorResponse | IDResponse;

export class ProjectStatusesResponse {
  statusId!: string;
  statusName!: string;
}

export class TeamPosition {
  id!: string;
  positionId!: string;
  positionName!: string;
  teamId!: string;
  userId!: string;
  userName!: string;
  vacancy!: Vacancy;
  integrations!: string[];
  email!: string;
}

export class TaskStatus {
  statusName!: string;
  count!: number;
}

export class Employee {
  id!: string;
  @ToDate('DD.MM.YYYY HH:mm')
  created!: Dayjs;
  @ToDate('DD.MM.YYYY HH:mm')
  changed!: Dayjs;
  userName!: string;
  positionName!: string;
  tasks!: TaskStatus[];
  workedHours!: number;
}

export class GetTeamAnalyticResponse extends PaginationClass {
  data!: Employee[];
}

export class Organization {
  id!: string;
  name!: string;
}

export const projectQueues: Queue[] = [
  {
    display: 'Очередь 1',
    key: 'LX',
    id: '1',
    self: '',
  },
  {
    display: 'Очередь 2',
    key: 'LX',
    id: '2',
    self: '',
  },
  {
    display: 'Очередь 3',
    key: 'LX',
    id: '3',
    self: '',
  },
];

export const projectTypes: ItemType[] = [
  {
    display: 'Тип 1',
    key: 'task',
    id: '1',
    self: '',
  },
  {
    display: 'Тип 2',
    key: 'task',
    id: '2',
    self: '',
  },
  {
    display: 'Тип 3',
    key: 'task',
    id: '3',
    self: '',
  },
];

export const projectPriorities: Priority[] = [
  {
    display: 'Приоритет 1',
    key: 'normal',
    id: '1',
    color: '',
    self: '',
  },
  {
    display: 'Приоритет 2',
    key: 'normal',
    id: '2',
    color: '',
    self: '',
  },
  {
    display: 'Приоритет 3',
    key: 'normal',
    id: '3',
    color: '',
    self: '',
  },
];

export interface BudgetAnalytics {
  planBudget: string;
  spentBudget: string;
  predictionBudget: string;
  costVariance: string;
  timeDeviation: string;
}

export const budgetAnalyticsData: BudgetAnalytics[] = [
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
  {
    planBudget: '1 000 000',
    spentBudget: '300 000',
    predictionBudget: '700 000',
    costVariance: '10 000 vs 20 000 / 10 000 vs 20 000',
    timeDeviation: '21.07.2024 vs 26.07.2024 / 21.07.2024 vs 26.07.2024',
  },
];

export class CommonAnalytics {
  spentHours!: number;
  executors!: number;
  daysUntilDeadline!: number;
  tasks!: {
    [TaskStatusType.Cancelled]: number;
    [TaskStatusType.Done]: number;
    [TaskStatusType.InProgress]: number;
    [TaskStatusType.New]: number;
    [TaskStatusType.Paused]: number;
  };
}

export const commonAnalytics: CommonAnalytics = {
  spentHours: 234,
  executors: 8,
  daysUntilDeadline: 22,
  tasks: {
    cancelled: 1,
    done: 2,
    inProgress: 3,
    new: 4,
    paused: 5,
  },
};

export class WIPAnalyticsResponse {
  userName!: string;
  userId!: string;
  data!: number;
}

export class WastedTimeAnalyticResponse {
  @ToDate('DD.MM')
  date!: Dayjs;
  hours!: number;
}

export class LeadTimeAnalyticResponse {
  daysCount!: number;
  taskCount!: number;
}

export class EffectivenessAnalyticResponse {
  @ToDate('DD.MM')
  date!: Dayjs;
  percent!: number;
}

export class CycleTimeAnalyticResponse {
  userName!: string;
  userId!: string;
  data!: number;
}

export class BurnoutAnalyticResponse {
  @ToDate('DD.MM')
  date!: string;
  closedTasks!: number;
  mustCloseTasks!: number;
}

export type ThroughputAnalyticResponseData = {
  executorName: string;
  taskCount: number;
  percentage: number;
};

export class ThroughputAnalyticResponse {
  @ToDate('DD.MM')
  startDate!: Dayjs;
  @ToDate('DD.MM')
  endDate!: Dayjs;
  totalTaskCount!: number;
  data!: ThroughputAnalyticResponseData[];
}

export type ProjectTransitionsField = {
  key?: string;
  display?: string;
  required?: boolean;
};

export type ProjectTransition = {
  id?: string;
  display?: string;
  fields?: ProjectTransitionsField[];
};

type YandexTrackerConnectionPayload = RequestBody<Api.operations['connectProjectToYandexTracker']>;

type SysTrackerConnectionPayload = RequestBody<Api.operations['connectProjectToSysTracker']>;

export type ConnectToTrackerMap = {
  [INTEGRATION_PROVIDER.YANDEX]: YandexTrackerConnectionPayload;
  [INTEGRATION_PROVIDER.SYS]: SysTrackerConnectionPayload;
  [INTEGRATION_PROVIDER.EGILE]: unknown;
};

export type DisconnectTrackerPayload = {
  projectId: string;
  tracker: INTEGRATION_PROVIDER;
};
