import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Project } from '@store/project/contracts';
import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import { ProjectsIds } from '@layouts/task/TasksLayout';
import { useLazyGetProjectsQuery } from '@store/project/project.slice';
import { useLocation } from 'react-router-dom';

export type SelectAutocompleteFormProps = {
  value: AutocompleteSearchValue;
  submit: (
    values: AutocompleteSearchValue,
    formikBag: FormikBag<SelectAutocompleteFormProps, AutocompleteSearchValue>,
  ) => void;
  setContext: React.Dispatch<React.SetStateAction<ProjectsIds>>;
};

export type AutocompleteSearchValue = {
  projectId: string;
};

export const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover': {
    outline: 'none',
  },
  '&.Mui-focused': {
    outline: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: '#007236',
  },
  width: 'fit-content',
  color: '#007236',
  fontWeight: 500,
  fontSize: '16px',
});

export const FormComponent: React.FC<SelectAutocompleteFormProps> = () => {
  const [searchText, setSearchText] = useState('');
  const [projects, setProjects] = useState<any>([]);
  const selectForm = useFormikContext<AutocompleteSearchValue>();
  const setContext = selectForm.getFieldProps('setContext').value;
  const [getProjects] = useLazyGetProjectsQuery();
  const location = useLocation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const projectsAvailable =
    projects?.data?.map((project: Project) => ({
      value: project.id,
      label: project.name,
    })) || [];

  const filteredProjects = projectsAvailable.filter((project: any) =>
    project.label.toLowerCase().includes(searchText.toLowerCase()),
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const request = { start: 0, limit: 50, sortOrder: 'desc' };
        const response = await getProjects(request);
        setProjects(response?.data);
        if (location.state?.projectId) {
          selectForm.setFieldValue('projectId', [location.state.projectId]);
          setContext({ projectIds: [location.state.projectId] });
        } else {
          selectForm.setFieldValue('projectId', [response?.data?.data[0]?.id]);
          setContext({ projectIds: [response?.data?.data[0]?.id] });
        }
      } catch (error) {}
    };
    fetchData();
  }, []);

  return (
    <Form onSubmit={selectForm.handleSubmit}>
      <Box>
        <FormControl size="small">
          <StyledSelect
            SelectDisplayProps={{ style: { padding: 0, paddingRight: '32px' } }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              autoFocus: false,
              MenuListProps: {
                disablePadding: true,
              },
              PaperProps: {
                elevation: 0,
                style: {
                  minWidth: '400px',
                  border: '1px solid #DCDCDC',
                  marginTop: '8px',
                },
              },
            }}
            IconComponent={ExpandMoreIcon}
            name="projectId"
            value={selectForm.values.projectId}
            onChange={(event: any) => {
              selectForm.setFieldValue('projectId', event.target.value);
              selectForm.submitForm();
            }}>
            <ListSubheader sx={{ border: 'none', padding: 0 }}>
              <TextField
                size="small"
                autoFocus
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Поиск"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                    padding: '16px',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#148C36 !important',
                  },
                  '&:focus .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#007236',
                  },
                }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {filteredProjects.map((option: any, i: any) => (
              <MenuItem
                key={i}
                value={option.value}
                sx={{ padding: '16px', borderTop: '1px solid #DCDCDC' }}>
                {option.label}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Box>
    </Form>
  );
};

export const SelectAutocompleteForm = withFormik<
  SelectAutocompleteFormProps,
  AutocompleteSearchValue
>({
  displayName: 'SelectAutocompleteForm',
  mapPropsToValues: (props) => {
    return {
      projectId: props.value.projectId,
      setContext: props.setContext,
    };
  },
  enableReinitialize: true,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(FormComponent);
