import React, { useCallback, useEffect, useState } from 'react';
import { TablePaginationMUI } from '@components/Table/TablePaginationMUI';
import { Vacancy } from '@store/vacancy/contracts';
import { Link, useOutletContext } from 'react-router-dom';
import { Dayjs } from 'dayjs';
import { More } from '@components/ui/More/More';
import { MenuItem, useMediaQuery, useTheme } from '@mui/material';
import { useRequestBody } from '@hooks/useRequestBody';
import { Star } from '@components/Star/Star';
import styles from '../Teams.module.scss';
import { AddVacancyButton } from '../buttons/AddVacancyButton';
import { ActionsModalButton } from '@components/MUI/ActionsModalButton/ActionsModalButton';
import css from './TeamMyVacancies.module.scss';
import { AdaptiveTableMUI } from '@components/adaptives/AdaptiveTableMUI';
import {
  useGetMyVacanciesQuery,
  useSetFavoriteVacancyMutation,
} from '@store/vacancy/vacancy.slice';
import { Can } from '@access-control';
import { ButtonCreateVacancy } from '@components/teams/Header/ButtonCreateVacancy';
import { VacanciesLayoutOutletContext } from '@layouts/vacancies/VacanciesLayout';
import { useEditVacancy } from '@hooks/useEditVacancy';
import { openModal } from '@store/ui/ui.slice';
import { CloseVacancyModal } from '@pages/vacancy/modals/CloseVacancyModal';
import { useAppDispatch } from '@hooks/index';

const TeamMyVacancies: React.FC = () => {
  const outletContext = useOutletContext<VacanciesLayoutOutletContext>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    outletContext.setRightSideButton(
      <Can I="create" a="Vacancies">
        <ButtonCreateVacancy />
      </Can>,
    );
  }, []);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState(1);
  const { requestBody, setRequestBody, sortColumn, setSortColumn, sortOrder, setSortOrder } =
    useRequestBody();
  const [setFavoriteVacancy] = useSetFavoriteVacancyMutation();

  const { handleEditVacancy } = useEditVacancy();

  const { data: vacancies, isLoading } = useGetMyVacanciesQuery({
    limit: limit,
    start: (page - 1) * limit,
    sortOrder: sortOrder,
    ...requestBody,
  });

  const handleChangeRowsPerPage = (newLimit: number) => {
    setLimit(newLimit);
    setPage(1);
  };

  const closeVacancyModal = useCallback((vacancyId: string) => {
    dispatch(openModal(<CloseVacancyModal vacancyId={vacancyId} />));
  }, []);

  return (
    <div className="col">
      <div className={styles.table__wrap}>
        <AdaptiveTableMUI
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          setSortOrder={setSortOrder}
          setRequestBody={setRequestBody}
          isLoading={isLoading}
          emptyLabel="Вакансии отсутствуют"
          columns={{
            name: {
              label: 'Вакансия',
              children: (value, row) => <Link to={`/vacancies/${row.id}`}>{value}</Link>,
              filterInput: true,
              isSortColumn: true,
            },
            actionsRaw: {
              children: () => <div></div>,
            },
            created: {
              label: 'Дата публикации',
              format: (value: Dayjs) => value.format('DD.MM.YYYY'),
              filterInput: true,
              isSortColumn: true,
            },
            teamName: {
              label: 'Команда',
              children: (value, row) => <Link to={`/teams/${row.teamId}`}>{value}</Link>,
              filterInput: true,
              isSortColumn: true,
            },
            positionName: {
              label: 'Роль',
              filterInput: true,
              isSortColumn: true,
            },
            directionName: {
              label: 'Направление',
              filterInput: true,
              isSortColumn: false,
            },
            stack: {
              label: 'Стек',
              filterInput: true,
              isSortColumn: true,
            },
            payment: {
              label: 'Ставка',
              children: (value: string, row: Vacancy) => <>{`${value} ${row.currencyName}`}</>,
              filterInput: true,
              isSortColumn: true,
            },
            comment: {
              label: 'Комментарий',
              children: (value) => <span className={styles.comment}>{value}</span>,
              filterInput: true,
              isSortColumn: true,
            },
            actions: {
              label: '',
              children: (_, row) =>
                row.statusName !== 'Закрыт' ? (
                  !isTablet ? (
                    <More>
                      <MenuItem
                        className={styles.btn_vacancy}
                        onClick={() => handleEditVacancy(row)}>
                        Редактировать
                      </MenuItem>
                      <MenuItem
                        className={styles.btn_vacancy}
                        onClick={() => closeVacancyModal(row?.id)}>
                        Закрыть
                      </MenuItem>
                    </More>
                  ) : (
                    <ActionsModalButton
                      children={() => (
                        <div className={css.modal}>
                          <button
                            onClick={() => handleEditVacancy(row)}
                            className={css.modal__button}>
                            Редактировать
                          </button>
                          <button
                            className={css.modal__button}
                            onClick={() => closeVacancyModal(row?.id)}>
                            Закрыть
                          </button>
                        </div>
                      )}
                    />
                  )
                ) : (
                  <></>
                ),
            },
            star: {
              children: (_, row: Vacancy) => (
                <Star
                  checked={row.favorite}
                  onChange={(value) =>
                    setFavoriteVacancy({ requestBody: { status: value }, vacancyId: row.id })
                  }
                  className={styles.star}
                />
              ),
            },
          }}
          rows={vacancies?.data || []}
          additionalElement={<AddVacancyButton />}>
          <TablePaginationMUI
            limit={limit}
            count={vacancies?.totalCount}
            entityLabel="вакансий"
            page={page}
            handleChangePage={(_, newPage) => setPage(newPage)}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </AdaptiveTableMUI>
      </div>
    </div>
  );
};

export { TeamMyVacancies };
