import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface FormikObserverProps {
  onChange: (values: any) => void;
}

export const FormikObserver = ({ onChange }: FormikObserverProps) => {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange(values);
  }, [values, onChange]);

  return null;
};
