import { useFormikContext } from 'formik';
import { AddTrackerValue } from '../AddTrackerForm';
import { Select, SelectOption } from '@components/MUI/Select';
import { SelectChangeEvent } from '@mui/material';
import styles from '../AddTrackerForm.module.scss';
import { useEffect, useState } from 'react';
import {
  listProjectInYandexOrganization,
  listYandexOrganization,
} from '@store/user/integration.service';

export const YandexTracker = () => {
  const form = useFormikContext<AddTrackerValue>();
  const [organizations, setOrganizations] = useState<SelectOption[]>([]);
  const [orgProjects, setOrgProjects] = useState<SelectOption[]>([]);

  useEffect(() => {
    listYandexOrganization().then((organizations) =>
      setOrganizations(organizations.map((org) => ({ value: org.id, label: org.title }))),
    );
  }, []);

  useEffect(() => {
    if (form.values.organizationId) {
      listProjectInYandexOrganization(form.values.organizationId).then((projects) =>
        setOrgProjects(projects.map((project) => ({ value: project.id, label: project.name }))),
      );
    }
  }, [form.values.organizationId]);

  return (
    <>
      <Select
        name="organizationId"
        values={organizations}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('organizationId', event.target.value);
        }}
        isOutlined={true}
        label="Организации"
        className={styles.select__organization}
      />
      <Select
        name="externalProjectId"
        values={orgProjects}
        onChange={(event: SelectChangeEvent) => {
          form.setFieldValue('externalProjectId', event.target.value);
        }}
        isOutlined={true}
        label="Проекты в организации"
        className={styles.select__project}
      />
    </>
  );
};
