import { Form, FormikBag, useFormikContext, withFormik } from 'formik';
import styles from './ProjectTransitionForm.module.scss';
import { Modal, ModalFooter } from '@components/MUI/Modal/Modal';
import { FormWrapper } from '@components/Form/Form';
import { Button } from '@components/MUI/Button';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import Api from '@api-schema';
import * as Yup from 'yup';
import { InputField } from '@components/MUI/InputField/InputField';

export type ProjectTransitionFields = Api.components['schemas']['ProjectFieldDTO'][];

type ProjectTransitionAdditionalFieldsValue = {
  transitionId: string;
  [name: string]: string;
};

export type ProjectTransitionAdditionalFieldsProps = {
  fields: ProjectTransitionFields;
  transitionId: string;
  submit: (
    value: ProjectTransitionAdditionalFieldsValue,
    form: FormikBag<ProjectTransitionAdditionalFieldsProps, ProjectTransitionAdditionalFieldsValue>,
  ) => void;
};

const Schema = Yup.object({
  transitionId: Yup.string().required('Выберите действие'),
});

export const ProjectTransitionAdditionalFields = (
  props: ProjectTransitionAdditionalFieldsProps,
) => {
  const { fields } = props;
  const dispatch = useAppDispatch();
  const form = useFormikContext<ProjectTransitionAdditionalFieldsValue>();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Modal>
      <FormWrapper label="Заполните поля">
        <Form onSubmit={form.handleSubmit} className={styles.form}>
          {fields.map((field) => (
            <InputField
              className={styles.transition_field}
              key={field.key!}
              type="text"
              name={field.key!}
              placeholder={field.display}
              required={field.required}
            />
          ))}
          <ModalFooter>
            <Button className="btn btn-sm text-secondary me-3" onClick={handleCloseModal}>
              Отменить
            </Button>
            <Button type="submit" variant="contained" disabled={!form.isValid}>
              Отправить
            </Button>
          </ModalFooter>
        </Form>
      </FormWrapper>
    </Modal>
  );
};

export const ProjectTransitionAdditionalFieldsForm = withFormik<
  ProjectTransitionAdditionalFieldsProps,
  ProjectTransitionAdditionalFieldsValue
>({
  displayName: 'MakeProjectTransitionForm',
  mapPropsToValues: (props) => {
    return {
      transitionId: props.transitionId,
    };
  },
  enableReinitialize: true,
  validationSchema: Schema,
  validateOnMount: true,
  validateOnChange: true,
  handleSubmit: (values, formikBag) => {
    formikBag.props.submit(values, formikBag);
  },
})(ProjectTransitionAdditionalFields);
