import React from 'react';
import { DelegationForm, DelegationFormProps } from '@pages/cloud/delegations/DelegationForm';
import { useAppDispatch } from '@hooks/index';
import { useNavigate } from 'react-router-dom';
import { createDelegation } from '@store/cloud/cloud.slice';
import dayjs from 'dayjs';
import { Card } from '@mui/material';
import classNames from 'classnames';
import styles from './DelegationForm.module.scss';

function CreateDelegationForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createSaveDelegation: DelegationFormProps['submit'] = async (DelegationForm, form) => {
    try {
      await dispatch(
        createDelegation({
          name: DelegationForm.name,
          parentId: DelegationForm.parentId,
          organization: {
            resourceId: DelegationForm.organizationId,
            resourceName: DelegationForm.organizationName,
            resourceType: 'organization',
          },
          cloud: {
            resourceId: DelegationForm.cloudId,
            resourceName: DelegationForm.cloudName,
            parentResourceId: DelegationForm.organizationId,
            resourceType: 'cloud',
          },
          billingAccount: {
            resourceId: DelegationForm.billingAccountId,
            resourceName: DelegationForm.billingAccountName,
            resourceType: 'billing_account',
          },
          projectId: DelegationForm.projectId,
          user: { email: DelegationForm.userEmail.value },
          isEnabledCreateInfrastructure: DelegationForm.isEnabledCreateInfrastructure,
          //infrastructureId: DelegationForm.infrastructureId,
          allocateBudget: DelegationForm.allocateBudget,
          currencyId: DelegationForm.currencyId,
          budget: DelegationForm.budget,
          budgetLimitActionId: DelegationForm.budgetLimitActionId,
          resetBudgetType: DelegationForm.resetBudgetType,
          budgetEndDate: dayjs(DelegationForm.budgetEndDate).format('YYYY-MM'), //dayjs(dayjs(DelegationForm.budgetEndDate).format('YYYY-MM-01')).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD'),
        }),
      ).unwrap();
      navigate(`/clouds/delegations`);
    } catch (e: any) {
      form.setErrors({
        name: e.message.name,
      });
    }
  };

  return (
    <Card
      className={classNames('form-container', styles.form__card)}
      elevation={0}
      variant="outlined">
      <div className={'row'}>
        <div className={styles.form__page__container}>
          <div className={styles.form__page__title}>
            <h2 className={'form-title'}>Делегировать</h2>
          </div>
          <DelegationForm
            value={{
              name: '',
              parentId: '',
              organizationId: '',
              organizationName: '',
              cloudId: '',
              cloudName: '',
              billingAccountId: '',
              billingAccountName: '',
              projectId: '',
              userEmail: { label: '', value: '' },
              isEnabledCreateInfrastructure: false,
              //infrastructureId: '',
              allocateBudget: false,
              currencyId: '',
              budget: 0,
              budgetLimitActionId: '',
              resetBudgetType: '',
              budgetEndDate: dayjs(),
              distributed: 0,
            }}
            submit={createSaveDelegation}
          />
        </div>
      </div>
    </Card>
  );
}

export { CreateDelegationForm };
