import React, { useCallback, useState } from 'react';
import { useAppDispatch } from '@hooks/index';
import { Box, Modal } from '@mui/material';
import { closeModal } from '@store/ui/ui.slice';
import { Button } from '@components/MUI/Button';
import styles from './CloseVacancyModal.module.scss';
import { useCloseVacancyMutation } from '@store/vacancy/vacancy.slice';

type VacancyModalProps = {
  vacancyId?: string;
};

export const CloseVacancyModal: React.FC<VacancyModalProps> = ({ vacancyId }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(true);
  const [closeVacancy] = useCloseVacancyMutation();

  const cancel = useCallback(() => {
    dispatch(closeModal());
    setOpen(false);
  }, [dispatch]);

  const handleCloseVacancy = useCallback(() => {
    if (vacancyId) {
      closeVacancy(vacancyId).then(() => {
        dispatch(closeModal());
      });
    }
  }, [vacancyId]);

  return (
    <Modal
      open={open}
      onClose={cancel}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ maxWidth: '480px', width: '100%' }}>
        <div className={styles.modal__content}>
          <h6 className={styles.modal__header}>Вы действительно хотите закрыть вакансию?</h6>
          <div className={styles.buttons}>
            <Button variant="text" onClick={cancel}>
              Отменить
            </Button>
            <Button variant="contained" onClick={handleCloseVacancy}>
              Закрыть
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
