import React, { useCallback } from 'react';
import { Modal } from '@components/MUI/Modal/Modal';
import { FormWrapper } from '@components/Form/Form';
import { AddTrackerForm, AddTrackerValue } from './AddTrackerForm';
import { useAppDispatch } from '@hooks/index';
import { closeModal } from '@store/ui/ui.slice';
import { useConnectTrackerToProjectMutation } from '@store/project/project.slice';

type TrackerModalProps = {
  projectId: string;
};

export const TrackerModal: React.FC<TrackerModalProps> = ({ projectId }) => {
  const dispatch = useAppDispatch();
  const [connectTrackerToProject] = useConnectTrackerToProjectMutation();

  const connectToTracker = useCallback(
    async (value: AddTrackerValue) => {
      const { tracker, ...rest } = value;

      const payload = { ...rest };
      connectTrackerToProject({
        projectId,
        tracker,
        payload,
      });

      dispatch(closeModal());
    },
    [dispatch],
  );

  return (
    <Modal>
      <FormWrapper label="Подключение трекера">
        <AddTrackerForm submit={connectToTracker} />
      </FormWrapper>
    </Modal>
  );
};
